import React from "react";
import numeral from "numeral";
import { Decimal } from "decimal.js-light";

const FORMAT = "$ 0,0.00[000]";

export default class MoneyInput extends React.Component {
  constructor(props) {
    super(props);
    const value = props.input.value || 0;

    this.state = {
      numericValue: Decimal(removeFormatting(value.toString())).toNumber(),
      formattedValue: numeral(value).format(FORMAT)
    };
  }

  onFocus = evt => {
    evt.target.select();
    this.props.input.onFocus();
  };

  onBlur = evt => {
    const stringValue = evt.target.value;
    this.setState({
      formattedValue: numeral(stringValue).format(FORMAT),
      numericValue: Decimal(removeFormatting(stringValue) || 0).toNumber()
    });
    this.props.input.onBlur();
  };

  onKeyDown = evt => {
    if (evt.metaKey) {
      return;
    }
    const stringValue = evt.target.value;
    // const numberValue = Decimal(removeFormatting(stringValue) || 0).toNumber();

    // Ensure key is whitelisted
    const validChars = "0123456789.,".split("");
    if (evt.key && evt.key.length == 1 && !validChars.includes(evt.key)) {
      evt.preventDefault();
      evt.stopPropagation();
      return;
    }

    // If value is currently zero and you start typing, just take that value
    if (
      stringValue == "$ 0.00" &&
      evt.target.selectionStart == stringValue.length
    ) {
      evt.target.value = "";
      // this.setState({ formattedValue: evt.target.value + evt.key });
      return;
    }

    // Limit decimal places
    let { decimalPlaces } = this.props;
    decimalPlaces = Math.min(parseInt(decimalPlaces || 2), 5);

    if (
      evt.key &&
      evt.key.length == 1 &&
      stringValue.includes(".") &&
      evt.target.selectionStart > stringValue.indexOf(".") &&
      stringValue.split(".")[1].length >= decimalPlaces
    ) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    // Prevent double decimal points
    const selectedValue = stringValue.substring(
      evt.target.selectionStart,
      evt.target.selectionEnd
    );
    if (
      evt.key == "." &&
      stringValue.includes(".") &&
      !selectedValue.includes(".")
    ) {
      evt.preventDefault();
      evt.stopPropagation();
      return;
    }
  };

  onChange = evt => {
    const { input } = this.props;
    const unformattedValue = removeFormatting(evt.target.value);

    this.setState({
      numericValue: Decimal(`0${unformattedValue}`).toNumber(),
      formattedValue: evt.target.value
    });

    if (unformattedValue == "") {
      input.onChange(0);
    } else {
      input.onChange(unformattedValue);
    }
  };

  componentDidUpdate() {
    const value = this.props.input.value || 0;

    // Allow the value to be set/updated from props
    if (
      numeral(value).format(FORMAT) != this.state.formattedValue &&
      this.refs.input != document.activeElement
    ) {
      this.setState({
        numericValue: Decimal(removeFormatting(value.toString())).toNumber(),
        formattedValue: numeral(value).format(FORMAT)
      });
    }
  }

  render() {
    const { input, id, className, autoFocus, autoComplete } = this.props;

    return (
      <input
        id={id}
        ref="input"
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        type="text"
        value={this.state.formattedValue}
        className={`${className ? className : ""} money-input`}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
      />
    );
  }
}

function removeFormatting(val) {
  if (val === ".") {
    return "";
  }

  return val.replace(/[^\d.-]/g, "").replace("..", ".");
}
