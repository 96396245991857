const initialState = {
  open: false,
  invoiceId: null,
  step: 'amount',
  amount: 0
}

const invoicePayment = (state = initialState, action) => {
  switch (action.type) {
    case 'START': {
      return { ...initialState, open: true, invoiceId: action.invoiceId }
    }

    case 'CLOSE': {
      return { ...initialState, open: false, invoiceId: null }
    }

    case 'CHANGE_STEP': {
      return { ...state, step: action.step }
    }

    case 'PAYMENT_AMOUNT_ENTERED': {
      return { ...state, amount: action.amount, step: 'payment_info' }
    }

    default:
      return state
  }
}

export default invoicePayment
