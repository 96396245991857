import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CustomerData } from "../queries";
import { PayInvoice } from "../mutations";
import { Field, reduxForm } from "redux-form";
import Payment from "payment";
import fetchJsonp from "fetch-jsonp";
import Cleave from "cleave.js/react";
import FormattedCurrency from "../components/FormattedCurrency";
import {
  Elements,
  CardElement,
  useElements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const mapState = ({ invoicePayment: { amount } }) => {
  return { amount };
};

export default CustomerData(PayInvoice(connect(mapState)(AmountScreen)));

function AmountScreen({
  loading,
  customer,
  amount,
  dispatch,
  invoice: { invoice_hash },
  payInvoice,
}) {
  const [errors, setErrors] = useState([]);
  const [stripe, setStripe] = useState();

  const gateway = document.head.querySelector(
    "[name='cohub_payment_gateway']"
  ).content;
  const cohubPaymentKey = (
    document.head.querySelector("[name='cohub_payment_key']") || {}
  ).content;
  const clientSecret = (
    document.head.querySelector("[name='client_secret']") || {}
  ).content;

  useEffect(() => {
    if (gateway === "stripe" && cohubPaymentKey && clientSecret) {
      loadStripe(cohubPaymentKey).then(setStripe);
    }
  }, [gateway, cohubPaymentKey, clientSecret]);

  const cardEntered = (elements) => (values) => {
    return new Promise((resolve, reject) => {
      const expirationYear =
        values.expiration && values.expiration.split("/")[1];

      console.log("Submitting", values);

      const card =
        gateway === "stripe"
          ? null
          : {
              kind: "credit_card",
              first_name: values.first_name,
              last_name: values.last_name,
              number: values.number.replace(/ /g, ""),
              verification_value: values.verification_value,
              month: values.expiration.split("/")[0],
              year:
                expirationYear.length == 2
                  ? "20" + expirationYear
                  : expirationYear,
            };

      if (gateway === "spreedly") {
        tokenizeSpreedly(card).then(resolve).catch(reject);
      } else if (gateway === "cayan") {
        tokenizeCayan(card).then(resolve).catch(reject);
      } else if (gateway === "stripe") {
        console.log("submitting stripe", elements);
        tokenizeStripe(elements).then(resolve).catch(reject);
      }
    })
      .then((token) => {
        console.log("submit payment with", token);
        return new Promise((resolve, reject) => {
          submitPayment(token).then(resolve).catch(reject);
        });
      })
      .then(() => dispatch({ type: "CHANGE_STEP", step: "thanks" }))
      .catch(setErrors);
  };

  const cardSelected = (values) => {
    return new Promise((resolve, reject) => {
      submitPayment(values.token).then(resolve).catch(reject);
    })
      .then(() => dispatch({ type: "CHANGE_STEP", step: "thanks" }))
      .catch(setErrors);
  };

  const submitPayment = (token) => {
    return new Promise((resolve, reject) => {
      payInvoice({
        variables: {
          invoiceHash: invoice_hash,
          paymentToken: token,
          amount: amount,
        },
      })
        .then(
          ({
            data: {
              PayInvoice: { success, invoice },
            },
          }) => (success ? resolve(invoice) : reject(invoice.errors))
        )
        .catch((error) => reject([`Error: ${error}`]));
    });
  };

  const tokenizeCayan = (card) => {
    return new Promise((resolve, reject) => {
      const cayanWebApiKey = document.head.querySelector(
        "[name='cohub_payment_key']"
      ).content;

      const data = {
        merchantApiKey: cayanWebApiKey,
        cardnumber: card.number.replace(" ", ""),
        cardholder: `${card.first_name} ${card.last_name}`,
        expirationmonth: card.month,
        expirationyear: card.year.substring(2, 4),
        cvv: card.verification_value,
      };

      $.ajax({
        url: "https://ecommerce.merchantware.net/v1/api/tokens",
        method: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: (response) => {
          if (response.errors && response.errors.length > 0) {
            reject("Unable to tokenize card.");
          } else {
            resolve(response.token);
          }
        },
        error: (error) => {
          reject(error.message);
        },
      });
    });
  };

  const tokenizeSpreedly = (card) => {
    return new Promise((resolve, reject) => {
      const environment_key = document.head.querySelector(
        "[name='cohub_payment_key']"
      ).content;

      card = { ...card, environment_key };

      var url = `https://core.spreedly.com/v1/payment_methods.js?${$.param(
        card
      )}`;

      fetchJsonp(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json.errors && json.errors.length > 0) {
            reject(json.errors.map((error) => error.message).join("\n"));
          } else {
            if (json.status === 201) {
              const payment_method = json.transaction.payment_method;
              resolve(payment_method.token);
            } else {
              reject(json.transaction.message);
            }
          }
        })
        .catch((err) => reject(err.message));
    });
  };

  const tokenizeStripe = (elements) => {
    return new Promise((resolve, reject) => {
      const card = elements.getElement("card");
      console.log("confirming card setup", card, clientSecret, stripe);
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: { card },
          expand: ["card"],
        })
        .then((result) => {
          console.log("got a result", result);
          if (result.error) {
            reject(result.error);
          } else if (result.setupIntent) {
            resolve(result.setupIntent.id);
          } else {
            reject({ message: "Unknown error" });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  if (loading) {
    return <div />;
  }

  console.log("payment-info", { stripe: !!stripe, gateway });

  return (
    <div>
      <h3>Payment Details</h3>
      {errors.length > 0 ? (
        <div className="errors">
          {errors.map((error, index) => (
            <div key={index} className="error">
              {error}
            </div>
          ))}
        </div>
      ) : null}
      <h4 id="payInvoiceAmountToPay">
        <span>Paying: </span>
        <span className="pay-invoice-money">
          <FormattedCurrency value={amount} />
        </span>
      </h4>
      {stripe ? (
        <Elements stripe={stripe} options={{ clientSecret }}>
          <ElementsConsumer>
            {({ elements }) =>
              customer ? (
                <CustomerPayment
                  cardEntered={cardEntered(elements)}
                  cardSelected={cardSelected}
                  customer={customer}
                  elements={elements}
                />
              ) : (
                <EnterCardForm
                  onSubmit={cardEntered(elements)}
                  elements={elements}
                />
              )
            }
          </ElementsConsumer>
        </Elements>
      ) : customer ? (
        <CustomerPayment
          cardEntered={cardEntered()}
          cardSelected={cardSelected}
          customer={customer}
        />
      ) : (
        <EnterCardForm onSubmit={cardEntered()} />
      )}
    </div>
  );
}

class CustomerPayment extends React.Component {
  state = {
    paymentMode: "new",
  };

  componentDidMount = () => {
    const {
      customer: { credit_cards },
    } = this.props;
    if (credit_cards.length > 0) {
      this.changeMode("saved");
    }
  };

  changeMode = (mode) => {
    this.setState({ ...this.state, paymentMode: mode });
  };

  render() {
    const { customer, cardEntered, cardSelected, elements } = this.props;
    const { paymentMode } = this.state;
    const initialValues = {
      first_name: customer.first_name,
      last_name: customer.last_name,
    };

    if (customer.credit_cards.length > 0) {
      return (
        <div className="content">
          <div>
            <div className="modes">
              <button
                className={`mode wallet ${
                  paymentMode == "saved" ? " active" : ""
                }`}
                onClick={() => this.changeMode("saved")}
              >
                Saved Card
              </button>
              <button
                className={`mode new ${paymentMode == "new" ? " active" : ""}`}
                onClick={() => this.changeMode("new")}
              >
                New Card
              </button>
            </div>

            {paymentMode == "saved" ? (
              <div className="wallet">
                <SelectCardForm
                  cards={customer.credit_cards}
                  onSubmit={cardSelected}
                />
              </div>
            ) : (
              <div className="new">
                <EnterCardForm
                  initialValues={initialValues}
                  onSubmit={cardEntered}
                  elements={elements}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <EnterCardForm
          initialValues={initialValues}
          onSubmit={cardEntered}
          elements={elements}
        />
      );
    }
  }
}

const EnterCardForm = reduxForm({ form: "Payment" })(
  ({ submitting, invalid, pristine, handleSubmit, elements }) => {
    useEffect(() => {
      if (elements) {
        return;
      }

      Payment.formatCardCVC(
        document.querySelector("[name='verification_value']")
      );
    }, [elements]);

    return (
      <div id="_cohub_EnterCardForm">
        <form onSubmit={handleSubmit}>
          <div className="fields">
            <div className="field">
              <label htmlFor="first_name">First Name</label>
              <Field
                id="first_name"
                name="first_name"
                component="input"
                autoComplete="given-name"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="last_name">Last Name</label>
              <Field
                id="last_name"
                name="last_name"
                component="input"
                autoComplete="family-name"
                required
              />
            </div>
          </div>
          {elements ? (
            <div className="field">
              <Field
                id="card-element"
                name="card-element"
                component={CardElement}
                required
              />
            </div>
          ) : (
            <>
              <div className="field">
                <label htmlFor="number">Credit Card Number</label>
                <Field
                  id="number"
                  name="number"
                  component={CardInput}
                  autoComplete="cc-number"
                  required
                />
              </div>
              <div className="fields">
                <div className="field">
                  <label htmlFor="expiration">Expiration</label>
                  <Field
                    id="expiration"
                    name="expiration"
                    component={ExpirationInput}
                    placeholder="MM/YYYY"
                    autoComplete="cc-exp"
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="verification_value">
                    Verification Number
                  </label>
                  <Field
                    id="verification_value"
                    name="verification_value"
                    component="input"
                    placeholder="CVV"
                    autoComplete="cc-csc"
                    required
                  />
                </div>
              </div>
            </>
          )}
          <button disabled={submitting || invalid || pristine} type="submit">
            {submitting ? "Please wait.." : "Pay"}
          </button>
        </form>
      </div>
    );
  }
);

@reduxForm({ form: "SelectPayment" })
class SelectCardForm extends React.Component {
  render() {
    const { cards, handleSubmit, submitting, invalid, pristine } = this.props;
    return (
      <div id="_cohub_CardSelectForm">
        <form onSubmit={handleSubmit}>
          {cards.map((cc) => (
            <div className="field" key={cc.id}>
              <label>
                <Field
                  name="token"
                  component="input"
                  type="radio"
                  value={cc.token}
                  required
                />
                <span className="brand">{cc.brand_and_last4}</span>
                <span className="expires">{cc.expiration}</span>
              </label>
            </div>
          ))}
          <button disabled={submitting || invalid || pristine} type="submit">
            Pay
          </button>
        </form>
      </div>
    );
  }
}

const CardInput = ({ id, input, ...restProps }) => (
  <Cleave
    id={id}
    placeholder="Enter Credit Card #"
    options={{ creditCard: true }}
    onChange={({ target: { value } }) => input.onChange(value)}
    onBlur={input.onBlur}
    value={input.value}
    {...restProps}
  />
);

const ExpirationInput = ({ id, input, ...restProps }) => (
  <Cleave
    id={id}
    placeholder="Expiration"
    options={{ date: true, datePattern: ["m", "Y"] }}
    onChange={(evt) => input.onChange(evt.target.value)}
    onBlur={input.onBlur}
    value={input.value}
    {...restProps}
  />
);
