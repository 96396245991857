import gql from "graphql-tag";
import { graphql } from "react-apollo";

const PayInvoiceMutation = gql`
  mutation PayInvoice(
    $invoiceHash: String
    $paymentToken: String
    $amount: MoneyInput
  ) {
    PayInvoice(
      input: {
        invoice_hash: $invoiceHash
        token: $paymentToken
        amount: $amount
      }
    ) {
      invoice {
        id
        errors
      }
      success
    }
  }
`;

export const PayInvoice = graphql(PayInvoiceMutation, { name: "payInvoice" });
