import React from "react";
import { connect } from "react-redux";
import FormattedCurrency from "../components/FormattedCurrency";

const mapState = ({ invoicePayment: { amount } }) => ({ amount });

@connect(mapState)
export default class ThanksScreen extends React.Component {
  handleClose = () => {
    this.props.dispatch({ type: "CLOSE" });
    location.reload();
  };

  render() {
    const { amount } = this.props;
    return (
      <div>
        <h3>Thanks!</h3>
        <h4>
          <span>Your payment of </span>
          <FormattedCurrency value={amount} />
          <span> has been accepted.</span>
        </h4>
        <button onClick={this.handleClose}>Ok</button>
      </div>
    );
  }
}
