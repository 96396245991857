import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import client from "./apollo";
import { ApolloProvider } from "react-apollo";
import App from "./app";
import "./style.scss";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";

const Root = ({ store, client, invoiceId }) => (
  <IntlProvider locale="en">
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App invoiceId={invoiceId} />
      </Provider>
    </ApolloProvider>
  </IntlProvider>
);

document.addEventListener("DOMContentLoaded", () => {
  let root = document.createElement("div");
  root.id = "cohubInvoicePaymentApp";

  ReactDOM.render(
    <Root store={store} client={client} />,
    document.body.appendChild(root)
  );

  let payInvoiceButtons = Array.from(
    document.getElementsByClassName("_cohub_pay_invoice_button")
  );
  payInvoiceButtons.map(button => {
    button.addEventListener("click", evt => {
      let invoiceId = button.attributes["data-invoice-id"];
      if (invoiceId) {
        invoiceId = invoiceId.value;

        if (invoiceId) {
          let payInvoiceEvent = new CustomEvent("cohubPayInvoice", {
            detail: { invoiceId }
          });
          document.dispatchEvent(payInvoiceEvent);
        }

        evt.stopPropagation();
      }
    });
  });
});
