import React from "react";
import { FormattedNumber } from "react-intl";

export default props => (
  <FormattedNumber
    style="currency"
    currency="USD"
    maximumFractionDigits={5}
    {...props}
  />
);
