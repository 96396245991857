import React from 'react'
import { connect } from 'react-redux'
import { InvoiceData } from './queries'
import AmountScreen from './screens/amount'
import PaymentInfoScreen from './screens/payment_info'
import ThanksScreen from './screens/thanks'

const mapState = ({invoicePayment}) => {
  return invoicePayment
}

@connect(mapState)
@InvoiceData
export default class App extends React.Component {

  componentDidMount() {
    document.addEventListener('cohubPayInvoice', this.startInvoicePayment)
  }

  componentWillUnmount() {
    document.removeEventListener('cohubPayInvoice', this.startInvoicePayment)
  }

  startInvoicePayment = ({ detail: { invoiceId }}) => {
    this.props.dispatch({ type: 'START', invoiceId })
  }

  handleClose = () => {
    this.props.dispatch({ type: "CLOSE" })
  }

  render() {
    const { open, invoiceId, step, loading, invoice } = this.props

    if(!open) {
      return(
        <div></div>
      )
    }

    if (loading) {
      return <div className="invoice-payment-loading">Loading...</div>
    }

    const screen = () => {
      switch(step) {
        case 'amount': {
          return <AmountScreen invoice={invoice} />
        }

        case 'payment_info': {
          return <PaymentInfoScreen invoice={invoice} />
        }

        case 'thanks': {
          return <ThanksScreen invoice={invoice} />
        }
      }
    }

    return (
      <div id="cohubInvoicePaymentAppContainer" ref={elem => this.container = elem}>
        <div id="cohubInvoicePaymentAppWrapper">
          <button id="cohubInvoicePaymentAppCloseButton" onClick={this.handleClose}>x</button>
          <h2 id="payInvoiceTitle">Pay Invoice {invoice.id}</h2>
          {screen()}
        </div>
      </div>
    )
  }
}
