import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import MoneyInput from "../components/MoneyInput";
import FormattedCurrency from "../components/FormattedCurrency";

@connect()
export default class AmountScreen extends React.Component {
  goToPayment = () => {
    this.props.dispatch({ type: "CHANGE_STEP", step: "payment_info" });
  };

  handleSubmit = values => {
    this.props.dispatch({
      type: "PAYMENT_AMOUNT_ENTERED",
      amount: values.amount
    });
  };

  render() {
    const { invoice } = this.props;
    return (
      <AmountForm
        invoice={invoice}
        initialValues={{ amount: invoice.balance }}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

@reduxForm({ form: "Amount" })
class AmountForm extends React.Component {
  render() {
    const { invoice } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <h3>Amount to Pay</h3>
        <h4 id="payInvoiceAmountDue">
          <span>Amount due</span>
          <span className="pay-invoice-money">
            <FormattedCurrency value={invoice.balance} />
          </span>
        </h4>
        <h4 id="payInvoiceAmountToPay">
          <span className="pay-invoice-money">
            <label htmlFor="amount">Amount to pay</label>
            <Field id="amount" name="amount" component={MoneyInput} />
          </span>
        </h4>
        <button type="submit">Next</button>
      </form>
    );
  }
}
