import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

const CustomerQuery = gql`
  query CustomerQuery {
    customer: current_customer {
      id
      email
      first_name
      last_name
      credit_cards {
        id
        brand_and_last4
        expiration
        token
      }
    }
  }
`

const CustomerQueryConfig = {
  options: (props) => {
    return { fetchPolicy: 'network-only' }
  },
  props: ({ data }) => {
    const { loading, customer } = data

    return { loading, customer }
  }
}

export const CustomerData = graphql(CustomerQuery, CustomerQueryConfig)

const InvoiceQuery = gql`
  query InvoiceQuery($invoiceHash:String ) {
    invoice: FindInvoice(invoice_hash: $invoiceHash) {
      id
      invoice_hash
      balance
      total
      invoice_date
      due_on
    }
  }
`

const InvoiceQueryConfig = {
  skip: ({ invoiceId }) => !invoiceId,
  options: (props) => {
    return {
      variables: {
        invoiceHash: props.invoiceId,
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ data }) => {
    const { loading, invoice } = data

    return { loading, invoice }
  }
}

export const InvoiceData = graphql(InvoiceQuery, InvoiceQueryConfig)
